@import './layers.scss';

@layer plugins {
  @import './plugins.scss';
}

@layer reset {
  @import './normalize.scss';
}

@layer global {
  @import './global.scss';
  @import './variables.scss';
  @import './scroll.scss';
}
