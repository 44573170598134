:root {
  --black: #000000;
  --black1: #23252F; 
  --black2: #14161F;
  --black3: rgba(255, 255, 255, 0.05);
  --black4: rgba(12, 12, 12, 0.3);
  --black5: rgba(255, 255, 255, 0.03);
  --black6: rgba(255, 255, 255, 0.6);
  --black7: #080A16;
  --black8: #2E2E33;
  --black9: rgba(255, 255, 255, 0.25);
  --black10: rgba(255, 255, 255, 0.06);
  --black11: rgba(255, 255, 255, 0.1);
  --black12: rgba(255, 255, 255, 0.07);
  --black13: #282930;
  --black14: #1C1D24;
  --black15: #2A2C35;
  --black16: rgba(12, 12, 12, 0.15);
  --black17: #2D2F38;
  --black18: #1C1E26;
  --black19: #1C1D24;
  --black20: #0C0C0C;
  --black21: #2E3039;
  --black22: #383A43;
  --black23: rgba(42, 44, 53, 0.6);
  --black24: #34363F;
  --black26: #313239;
  --black27: #272933;
  --black28: #393B44;
  --black29: #2A2C36;
  --black30: #32343E;
  --black32: #919297;
  --black33: #474951;
  --black34: #30323B;
  --black35: #3A3C45;
  
  --blue1: #58B2ED;
  --blue2: #2EC5FF;
  --blue3: #58B2ED;
  --blue4: #00C2FF;

  --aqua1: rgba(88, 178, 237, 0.2);
  --aqua2: #2E4155;
  
  --white: #FFFFFF;
  --white1: #ECECEC;
  --white2: rgba(255, 255, 255, 0.3);
  --white3: rgba(255, 255, 255, 0.5);
  --white4: #D3D3D5;
  
  --grey: #9A9A9A;
  --grey1: #353845;
  --grey2: rgba(255, 255, 255, 0.5);
  --grey3: rgba(236, 236, 236, 0.30);
  --grey4: rgba(255, 255, 255, 0.2);
  --grey5: #5F6064;
  --grey6: #898A8F;
  --grey7: #65666D;
  --grey8: #919297;
  --grey9: #AAABAF;
  
  --orange1: #DA6518;
  --orange2: rgba(255, 138, 0, 0.05);
  
  --red: #BB0000;
  --red2: rgba(132, 2, 7, 0.2);
  --red3: #C00;
  --red4: rgba(204, 0, 0, 0.05);
  --red5: #FF2B2B;
  
  --green: #15975B;
  --green1: #109C52;
  
  --yellow: #FFCF06;
  --yellow1: rgba(255, 207, 6, 0.05);

  --max-width: 1360px;
}
  